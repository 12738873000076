<template>
  <div class="app-container">
    <el-row>
      <div>基本信息</div>
      <el-divider />
    </el-row>
    <el-form
      ref="reconciliationForm"
      :model="reconciliationForm"
      label-width="100px"
    >
      <el-row class="row-bg">
        <el-col :span="8">
          <el-form-item label="对账单号" prop="">
            <el-input
              v-model="reconciliationForm.checkBillCode"
              :placeholder="$t('page.inputPlaceholder')"
              disabled
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="供应商">
            <el-input
              v-model="reconciliationForm.vendorName"
              :placeholder="$t('page.inputPlaceholder')"
              disabled
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="状态">
            <el-input
              v-model="reconciliationForm.status"
              :placeholder="$t('page.inputPlaceholder')"
              disabled
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="对账人">
            <el-input
              v-model="reconciliationForm.checkBillUser"
              :placeholder="$t('page.inputPlaceholder')"
              disabled
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="对账时间">
            <el-input
              v-model="reconciliationForm.checkBillTime"
              :placeholder="$t('page.inputPlaceholder')"
              disabled
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-row>
      <div>对账明细</div>
      <el-divider />
    </el-row>
    <el-table
      ref="multipleTable"
      v-loading="TableLoading"
      class="mb-3"
      :data="tableDatas"
      :summary-method="getSummaries"
      show-summary
      :header-cell-style="{background:'#fafafa',color:'#606266'}"
      max-height="400px"
      style="width: 100%"
    >
      <el-table-column
        :label="$t('page.No')"
        type="index"
        width="80"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        prop="purchaseOrderCode"
        label="采购订单"
        width="180"
        align="center"
        sortable
      />
      <el-table-column
        label="规格型号"
        width="180"
        align="center"
        sortable
      >
        <template slot-scope="scope">
          {{ scope.row.style }}/{{ scope.row.color }}/{{ scope.row.size }}
        </template>
      </el-table-column>
      <el-table-column
        prop="fnsku"
        label="FNSKU"
        width="180"
        align="center"
        sortable
      />
      <el-table-column
        prop="upc"
        label="UPC"
        width="180"
        align="center"
        sortable
      />
      <el-table-column
        prop="actualPurchasePrice"
        label="实际采购单价"
        min-width="135"
        align="center"
        sortable
      />
      <el-table-column
        prop="checkBillNumber"
        label="已对账数量"
        min-width="115"
        align="center"
        sortable
      />
      <el-table-column
        prop="checkBillMoney"
        label="已对账金额"
        min-width="115"
        align="center"
        sortable
      />
    </el-table>
    <div class="block">
      <pagination
        :pager="pager"
        @pagination="_turnoverpage"
      />
    </div>
  </div>
</template>

<script>
import { checkBillById } from '@/api/scm-api'
import Pagination from '@/components/Pagination'
export default {
  components: { Pagination },
  data() {
    return {
      reconciliationForm: {},
      tableDatas: [],
      TableLoading: false,
      pager: {
        total: 0,
        size: 10,
        current: 1
      }
    }
  },
  created() {

  },
  mounted() {
    this._checkBillById(this.$route.query.id)
  },
  methods: {
    _turnoverpage() {},
    async _checkBillById(id) {
      try {
        this.TableLoading = true
        const { datas, code } = await checkBillById(id)
        // console.log(datas)
        const { checkBillCode, vendorName, status, checkBillUser, checkBillTime, checkBillDetails } = datas
        let Status = ''
        status === 1 ? Status = '对账中' : status === 2 ? Status = '已对账' : status === 9 ? Status = '已作废' : ''
        this.reconciliationForm = { checkBillCode, vendorName, status: Status, checkBillUser, checkBillTime }
        this.tableDatas = checkBillDetails
        code === 0 ? this.TableLoading = false : ''
        return
      } finally {
        this.TableLoading = false
      }
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        } else if (index === 7 || index === 6) {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = Number(values.reduce((prev, curr) => {
              const value = Number(curr)
              prev = (prev + '').indexOf('.') === -1 ? prev : Number(prev.toFixed(2))
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)).toFixed(2)
          }
        } else {
          sums[index] = '-'
        }
      })
      return sums
    }
  }
}
</script>

<style scoped lang="scss">

</style>
